@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700&family=Roboto:ital,wght@0,300;0,400;1,300&display=swap");

.contact-head img {
  width: 100%;
  height: 80vh;
  /* object-fit: cover; */
}

.contact-main {
  background-color: #f5f5f5;
}

.contact-flex {
  display: flex;
  gap: 5rem;
  align-items: center;
  padding-bottom: 3rem;
  /* justify-content: center; */
}

.contact-body {
  padding: 4rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 5rem;
}

.contact-title {
  font: normal bold 35px/1 "Alegreya";
  text-align: center;
  margin-bottom: 30px;
  color: #1a237e;
}

.contact-sub-title {
  font: normal 18px/1 "Alegreya";
  margin-bottom: 40px;
}

.form-input input {
  width: 580px;
  height: 60px;
  margin-bottom: 18px;
  outline: none;
  padding: 18px 22px;
  border: 1px solid white;
}

.message {
  width: 580px;
  height: 135px;
  margin-bottom: 25px;
  outline: none;
  padding: 18px 22px;
  border: 1px solid white;
}

.submit-btn {
  width: 100%;
  border: none;
  background-color: #0e1843;
  outline: none;
  color: white;
  padding: 15px 0;
  font: normal 18px/1 "Alegreya";
}

::placeholder {
  font: normal 16px/1 "Alegreya";
}

.contact-btm-div {
  margin: 4rem 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 3rem 4rem;
  background-color: white;
}

.contact-bottom-title {
  font: normal bold 30px/1 "Alegreya";
  margin-bottom: 20px;
  color: #1a237e;
}

.contact-bottom-flex p {
  font: normal 18px/1 "Alegreya";
  text-align: center;
}


.Error-modal {
  color: red;
  font: normal bold 25px/1.4 'Alegreya';
  text-align: center;
}

.Result-modal {
  color: green;
  font: normal bold 25px/1.4 'Alegreya';
  text-align: center;
}

/* Media Querry */

@media only screen and (max-width: 1060px) {
  .contact-head img {
    width: 100%;
    height: 50vh;
  }

  .contact-flex {
    flex-wrap: wrap;
    gap: 2rem;
  }

  .form-input input {
    width: 100%;
    padding: 5px 10px;
  }

  .contact-body {
    padding: 20px;
    margin-top: 2rem;
  }

  .message {
    width: 100%;
  }

  .contact-title {
    font: normal bold 30px/1 "alegreya";
    margin-bottom: 15px;
  }

  .contact-sub-title {
    font: normal 17px/25px "Alegreya";
    text-align: center;
  }

  .contact-btm-div {
    padding: 20px;
    margin: 20px 5px;
  }
}