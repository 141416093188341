@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700&family=Roboto:ital,wght@0,300;0,400;1,300&display=swap");

.about-head {
  position: relative;
  background-image: url("../Assets/abt-main.jpg");
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
}
.about-head-img {
  width: 100%;
  height: 65vh;
}
.about-main-title {
  position: absolute;
  font: normal bold 70px/1 "Alegreya";
  text-transform: uppercase;
  top: 50%;
  left: 42%;
  color: white;
  opacity: 0.8;
}

/* Story Section */

.about-body-flex {
  margin: 5rem 0;
  display: flex;
  align-items: center;
  gap: 5rem;
}
.our-story-title {
  font: normal bold 33px/1 "Alegreya";
  color: #1a237e;
  margin-bottom: 25px;
}
.story-right img {
  width: 500px;
  height: 500px;
  object-fit: cover;
  border-radius: 7px;
}
.story-left p {
  font: normal 17px/35px "Alegreya";
}
.director-title {
  font: normal bold 35px/1 "Alegreya";
  color: #1a237e;
  text-align: center;
  margin-bottom: 4rem;
}

/* Directors Image Flex */

.director-content {
  /* background-image: linear-gradient(to right, #021b79, #0575e6); */
  background-color: #0e1843;
  margin: 0;
  padding: 15px 0;
  color: white;
  width: 100%;
}

.director-content h3 {
  font: normal bold 20px/1 "Alegreya";
  letter-spacing: 0.5px;
  margin: 10px 0;
  text-align: center;
  text-transform: uppercase;
}
.director-content p {
  font: normal bold 17px/1 "Alegreya";
  text-align: center;
}

.flex-main {
  display: flex;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 9rem;
}

.front > img {
  display: block;
  width: 400px;
  height: 400px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 7px;
}

.director-main {
  position: relative;
  width: 400px;
  height: 400px;
  perspective: 1000px;
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: fit-content;
  backface-visibility: hidden;
  border-radius: 7px;
  background-color: #0e1843;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
  transition: transform 0.8s;
}

.back p {
  padding: 15px;
  font: normal bold 18px/25px "alegreya";
  text-align: justify;
  color: white;
}
.back {
  transform: rotateY(180deg);
  transition: transform 0.8s;
  height: 100%;
}
.director-main:hover .front {
  transform: rotateY(-180deg);
}
.director-main:hover .back {
  transform: rotateY(0deg);
}

/* Media Querry */

@media only screen and (max-width: 1060px) {
  .about-head-img {
    height: 50vh;
  }
  .flex-main {
    flex-wrap: wrap;
    gap: 8rem;
  }
  .front img {
    width: 100%;
  }
  .director-title {
    font: normal bold 35px/1 "alegreya";
    margin-bottom: 2rem;
  }
  .director-content h3 {
    font: normal bold 18px/1 "Alegreya";
    margin: 20px 0;
  }
  .director-content p {
    font: normal 16px/1 "Alegreya";
  }
  .about-main-title {
    font: normal bold 40px/1 "Alegreya";
    text-align: center;
    left: 25%;
  }
  .about-body-flex {
    flex-wrap: wrap;
    margin: 2rem;
    gap: 25px;
  }
  .story-right img {
    width: 100%;
    height: 350px;
  }
  .our-story-title {
    font: normal bold 30px/1 "Alegreya";
  }
  .story-left p {
    font: normal 17px/25px "Alegreya";
  }
  .awards-left p {
    font: normal 17px/25px "Alegreya";
  }
}
