.process_component_grid {
    margin: auto;
    width: 80%;
    gap: 1rem;
}

.process_component_grid_para::first-letter{
    font-size: 25px;
    margin-left: 25px;
}

@media (max-width:768px){
    .process_component_grid {
        width: 100%;
        gap: 0.2rem;
    }
}