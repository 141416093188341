@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700&family=Roboto:ital,wght@0,300;0,400;1,300&display=swap");
.home-head {
  margin-bottom: 3rem;
}
.home-head img {
  width: 100%;
  object-fit: cover;
  height: 65vh;
}
.home-body {
  position: relative;
  margin-bottom: 14rem;
  margin-top: 5rem;
}
.slide-title {
  font: normal bold 35px/1 "Alegreya";
  /* margin-bottom: 15px; */
}
.slide-content {
  font: normal bold 30px/1 "Alegreya";
}
.slide-div {
  background-color: #e1f5fe;
  padding: 15px 0;
  transition: 0.4s all ease;
  border-radius: 10px;
  width: 100%;
  color: black;
}
.slide-div:nth-child(even) {
  background-color: green;
}
.home-second-div-two {
  padding: 60px 20px;
  background-color: #0e1843;
  color: white;
  width: 600px;
  position: absolute;
  bottom: -20%;
  right: -3%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.home-sub-title {
  font: normal bold 32px/45px "Alegreya";
  margin-bottom: 25px;
  /* color: red; */
}
.home-sub-content {
  font: normal 17px/33px "Alegreya";
  margin-bottom: 45px;
}
.home-btn {
  font: normal 17px/1 "Alegreya";
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.home-icon {
  margin-left: 10px;
  font-size: 20px;
}
.home-btn:hover {
  color: yellow;
}

/* Core Values Section */
.core-values-main {
  margin: 5rem 0;
}
.core-value-head {
  margin: 0 13rem;
  text-align: center;
}
.core-value-main-title {
  font: normal bold 32px/1 "Alegreya";
  margin-bottom: 20px;
  color: #0e1843;
}
.core-value-para {
  font: normal 17px/35px "alegreya";
  margin-bottom: 20px;
}
.core-value-flex {
  display: flex;
  justify-content: space-between;
  background-color: #e7e7e7;
  margin-bottom: 1rem;
}
.core-value-card {
  width: max-content;
  padding: 50px 20px;
  text-align: center;
  margin: 0;
  transition: 0.5s all ease-in-out;
  border-right: 1px solid #ccc;
}
.core-value-card:last-child {
  border-right: none;
}
.core-value-title {
  font: normal bold 25px/1 "Alegreya";
  margin-bottom: 15px;
}
.core-value-content {
  font: normal 17px/35px "Alegreya";
  margin-bottom: 15px;
}
.core-value-icon {
  font-size: 50px;
  margin-bottom: 15px;
}
.core-value-card:hover {
  background-color: #0e1843;
  color: white;
}
/* Who We are */
.who-we-are-container {
  position: relative;
  /* margin: 20px 40px; */
}
.who-we-are-body {
  background-image: url("../Assets/home2.jpg");
  height: 80vh;
  background-position: center;
  background-size: 650px;
  background-repeat: repeat;
}
.who-we-are-body img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  border-radius: 10px;
}
.who-we-are-title {
  font: normal bold 32px/1 "Alegreya";
  text-align: center;
  margin-bottom: 25px;
}
.who-we-are-content {
  padding: 40px 40px;
  width: 520px;
  text-align: justify;
  background-color: rgba(14, 24, 67, 0.8);
  color: #fafafa;
  position: absolute;
  bottom: 0;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
}
.who-we-are-para {
  font: normal 17px/30px "Alegreya";
  margin-bottom: 3rem;
}
.who-we-are-btn {
  outline: none;
  border: none;
  padding: 12px 30px;
  background-color: #0e1843;
  border: 1px solid white;
  font: normal 18px/1 "Alegreya";
  color: white;
  text-decoration: none;
}
.who-we-are-btn:hover {
  color: yellow;
}
/* Choose Us Flex */

.choose-us-flex {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: center;
  margin-bottom: 3rem;
}
.choose-left img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 6px;
}
.choose-title {
  font: normal bold 32px/1 "Alegreya";
  text-align: center;
  margin: 2rem 0;
  color: #1a237e;
}
.choose-us-card {
  width: 420px;
  padding: 52px;
  border: 1px solid #e7e7e7;
  margin: 2px;
  border-radius: 5px;
}
.choose-card-title {
  font: normal bold 25px/1 "Alegreya";
  margin-bottom: 15px;
}
.choose-card-content {
  font: normal 17px/28px "Alegreya";
  text-align: justify;
}
.choose-us-right-flex {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.slide-div {
  animation: fadeIn 6s ease;
  opacity: 0.7;
}
.slide-title {
  animation: fadeIn 1s ease-in-out;
}
.slide-content {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .slide-title {
    font: normal bold 30px/1 "alegreya";
  }
  .home-sub-title {
    font: normal bold 30px/40px "Alegreya";
  }
  .who-we-are-body {
    background-image: none;
    height: auto;
  }
  .who-we-are-title {
    font: normal bold 30px/1 "alegreya";
    padding-top: 15px;
  }
  .who-we-are-para {
    font: normal 17px/25px "Alegreya";
  }
  .home-second-div img {
    display: none;
  }
  .home-second-div-two {
    position: relative;
    padding: 20px 15px;
    width: 100%;
    height: max-content;
    top: 0;
    right: 0;
    bottom: 0;
    margin-bottom: -145px;
  }
  .core-values-main {
    margin: 10px;
  }
  .core-value-head {
    margin: 10px;
  }
  .core-value-flex {
    flex-wrap: wrap;
  }
  .core-value-card {
    padding: 20px;
  }
  .who-we-are-content {
    width: 100%;
    position: relative;
    padding: 10px 20px;
  }
  .choose-us-flex {
    flex-wrap: wrap;
  }
  .choose-left img {
    width: 100%;
  }
  .choose-us-card {
    width: 100%;
    padding: 10px;
  }
}
