@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700&family=Roboto:ital,wght@0,300;0,400;1,300&display=swap");

.certificate-main-title {
  font: normal bold 40px/1 "Alegreya";
  margin-top: 3rem;
  text-align: center;
  color: #1a237e;
}

.carousel-card img {
  width: 110px;
  height: 110px;
  object-fit: contain;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 40px 0;
  transition: 0.3s all ease-in-out;
}
.carousel-card img:hover {
  transform: scale(1.1);
}


@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9));
  }
}
.certificate-multi-carousel {
  padding: 20px 0;
  height: max-content;
  margin: 2rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.carousel-card {
  /* background-color: #e7e7e7; */
  animation: scroll 20s linear infinite;
  width: 150px;
}

/* Awards Main */

.awards-main {
  display: flex;
  align-items: center;
  gap: 4rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
}
.awards-right {
  padding: 15px;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.awards-right img {
  width: 150px;
  height: 150px;
  margin: 1px;
  transition: 0.5s all ease-in-out;
  border-radius: 7px;
}
.awards-right img:hover {
  transform: scale(1.2);
}
.awards-left p {
  font: normal 18px/35px "Alegreya";
  text-align: justify;
}

/* Compliance Section */

.compliance-main {
  display: flex;
  align-items: center;
  gap: 4rem;
  margin-bottom: 6rem;
}
.compliance-right p {
  font: normal 18px/35px "Alegreya";
  text-align: justify;
}
.compliance-main-title {
  font: normal bold 35px/1 "Alegreya";
  color: #1a237e;
  margin-bottom: 30px;
}
.compliance-main-title-two {
  font: normal bold 35px/1 "Alegreya";
  color: #1a237e;
  margin-bottom: 30px;
  text-align: left;
}
.compliance-left {
  display: flex;
  padding: 12px;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.compliance-left img {
  width: 150px;
  height: 150px;
  margin: 3px;
  border-radius: 7px;
  transition: 0.4s all ease-in-out;
}
.compliance-left img:hover {
  transform: scale(1.1);
}
/* Media Querry */
@media only screen and (max-width: 760px) {
  .certificate-multi-carousel {
    padding: 2px;
  }
  .awards-main {
    flex-wrap: wrap;
    gap: 1rem;
  }
  .awards-right img {
    width: 100%;
    height: auto;
  }
  .compliance-main {
    flex-wrap: wrap;
  }
  .compliance-left img {
    width: 100%;
  }
}
