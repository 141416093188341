@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700&family=Roboto:ital,wght@0,300;0,400;1,300&display=swap");

.process-head img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}
.process-main {
  background-color: #e8eaf6;
}
.process-head {
  position: relative;
}
.process-title {
  position: absolute;
  top: 59%;
  left: 45%;
  font: normal bold 70px/1 "Alegreya";
  color: #1a237e;
}
/* Process Body */
.process-body {
  display: flex;
  /* align-items: center; */
  padding: 4rem 0;
  gap: 2rem;
}
.process-head-content {
  padding: 3rem 9rem;
  border-bottom: 1px solid white;

  text-align: center;
}
.process-sub-title {
  font: normal bold 33px/1 "Alegreya";
  text-transform: uppercase;
  color: #1a237e;
  text-align: center;
  margin-bottom: 20px;
}
.process-sub-content {
  font: normal 20px/35px "Alegreya";
}
.process-body-left {
  width: 800px;
  padding: 20px 10px;
}
.process-body-left img {
  width: 650px;
  height: 700px;
  border-radius: 10px;
  object-fit: cover;
  margin-top: 1rem;
}

.process-right-title span {
  color: #1a237e;
}

.process-body-right p {
  font: normal 18px/24px "Alegreya";
  margin: 0;
}
.process-icon {
  font-size: 20px;
}
.process-right-title {
  font: normal bold 30px/1 "Alegreya";
  text-transform: uppercase;
  margin-bottom: 25px;
  text-align: center;
}
.process-body-sub-div {
  opacity: 0;
  transform: translateY(20px);
  background-color: white;
  margin-bottom: 5px;
  padding: 20px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.animated {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.process-body-sub-div:nth-child(1) {
  animation-delay: 0.5s;
}

.process-body-sub-div:nth-child(2) {
  animation-delay: 1s;
}

.process-body-sub-div:nth-child(3) {
  animation-delay: 1.5s;
}

.process-body-sub-div:nth-child(4) {
  animation-delay: 2s;
}

.process-body-sub-div:nth-child(5) {
  animation-delay: 2.5s;
}

.process-body-sub-div:nth-child(6) {
  animation-delay: 3s;
}

.process-body-sub-div:nth-child(7) {
  animation-delay: 3.5s;
}

.animated:nth-child(even) {
  animation-name: fadeIn;
}
.animated:nth-child(odd) {
  animation-name: fadeIn;
}

/* Media Querry */
@media only screen and (max-width: 750px) {
  .process-head img {
    height: 50vh;
  }
  .process-title {
    font: normal bold 50px/1 "Alegreya";
    left: 27%;
  }
  .process-sub-title {
    font: normal bold 30px/1 "Alegreya";
    margin-top: 20px;
  }
  .process-sub-content {
    font: normal 18px/30px "Alegreya";
  }
  .process-head-content {
    padding: 10px;
  }
  .process-body {
    flex-wrap: wrap;
  }
  .process-body-left img {
    width: 100%;
    height: 400px;
  }
  .process-body-sub-div {
    padding: 10px 5px;
    gap: 10px;
  }
}
