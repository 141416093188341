@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700&family=Roboto:ital,wght@0,300;0,400;1,300&display=swap");

* {
  margin: 0;
  padding: 0;
}

.navbar-main-links {
  padding: 10px 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 5rem;
  align-items: center;
  list-style: none;
  border-bottom: 1px solid #e7e7e7;
}

.navbar-main-links li {
  font: normal bold 22px/1 "Alegreya";
}

.nav-logo {
  width: 250px;
  height: 70px;
  object-fit: cover;
}

.nav-links {
  text-decoration: none;
  color: black;
}

.nav-links:hover {
  color: #1a237e;
}

.dropdown-content {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
  margin-top: 5px;
}

.dropdown-content {
  position: absolute;
  background-color: #1a237e;
  min-width: 160px;
  z-index: 1111;
  cursor: pointer;
  list-style: none;
  text-decoration: none;
  padding: 0;
  text-align: left;
}

.dropdown-content a {
  text-decoration: none;
  color: white;
  font: normal bold 18px/1 "Alegreya";
}

.dropdown-content li {
  padding: 10px;
  color: white;
  margin-bottom: 15px;
}

.dropdown-content a:hover {
  color: #000;
}

.dropdown-content li:hover {
  background-color: #ddd;
  /* color: black; */
}

/* Menu icon css */
.menu_icon {
  margin-left: 2%;
}

.nav-logo2 {
  width: 250px;
  height: 70px;
  object-fit: cover;
  justify-content: center;
  display: flex;
  margin: auto;
  display: none;
}

.mobile_nav {
  display: flex;
  align-items: center;
  display: none;
}




/* Media Querry */
@media only screen and (max-width: 1060px) {
  .navbar-main-links {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    gap: 20px;
    transition: all 200s ease-in-out !important;
  }

  .nav-logo {
    display: none;
  }

  .nav-logo2 {
    display: block;
  }

  .navbar-main {
    background-color: white;
  }

  .nav-links {
    /* color: white; */
    text-align: left;
  }

  .mobile_nav {
    display: flex;
  }

  .none {
    display: none;
    transition: all 200s ease-in-out !important;
  }
}