@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700&family=Roboto:ital,wght@0,300;0,400;1,300&display=swap");
.collection-head img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}
.collection-body {
  margin: 5rem 0;
}
.collection-main-title {
  font: normal bold 35px/1 "Alegreya";
  color: #021b79;
  text-align: center;
  margin-bottom: 5rem;
}
.collection-top-content {
  padding: 40px 60px;
  margin: 1rem 12rem;
  font: normal 20px/35px "Alegreya";
  text-align: justify;
}
.collection-top-title {
  padding: 40px 60px;
  margin: 1rem 12rem;
  font: normal bold 40px/1 "Alegreya";
  text-align: center;
  color: #021b79;
}
.collection-img-flex {
  display: flex;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 7rem;
  width: 100%;
  transition: filter 0.5s;
}
.h3-text {
  position: absolute;
  bottom: 0;
  opacity: 0;
  left: 100px;
  transition: opacity 0.1s, transform 0.4s;
}
.h3-text h3 {
  font: normal bold 35px/1 "Alegrerya";
}
.product-inner-flex:hover .collection-img-flex-img {
  filter: blur(4px);
}

.product-inner-flex:hover .h3-text {
  opacity: 1;
}

.collection-img-flex-img {
  width: 350px;
  height: 350px;
  object-fit: cover;
  padding: 4px;
  border-radius: 7px;
  transition: all 1s ease-in-out;
  /* border: 3px solid  #0e1843; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background-image: linear-gradient( #0e1843, #0e1843);
}

.collection-img-flex-img.animate {
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.animated {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.product-inner-flex {
  position: relative;
  width: 300px;
  height: 300px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.collection-img-flex-img:nth-child(1) {
  animation-delay: 0.5s;
}

.collection-img-flex-img:nth-child(2) {
  animation-delay: 1s;
}

.collection-img-flex-img:nth-child(3) {
  animation-delay: 1.5s;
}

.collection-img-flex-img:nth-child(4) {
  animation-delay: 2s;
}

.animated:nth-child(even) {
  animation-name: fadeIn;
}
.animated:nth-child(odd) {
  animation-name: fadeIn;
}

/* Media Querry */

@media only screen and (max-width: 1060px) {
  .collection-head img {
    width: 100%;
    height: 50vh;
  }
  .collection-top-content {
    padding: 10px;
    margin: 20px;
    font: normal 17px/28px "Alegreya";
  }
  .collection-img-flex {
    flex-wrap: wrap;
    padding: 10px;
    gap: 1rem;
    /* margin-bottom: 10px; */
    /* margin: 0 10px; */
  }
  .collection-img-flex-img {
    width: 100%;
    height: 300px;
  }
  .collection-top-title {
    padding: 15px 20px;
    margin: 0;
    font: normal bold 30px/1 "Alegreya";
  }
  .collection-body {
    margin: 2rem 0;
  }
  .collection-main-title {
    margin-bottom: 2rem;
    font: normal bold 30px/1 "Alegreya";
  }
}
