@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700&family=Roboto:ital,wght@0,300;0,400;1,300&display=swap");

.footer-top-flex {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #0e1843;
  color: white;
}
.footer-flex {
  display: flex;
  justify-content: space-evenly;
}
.footer-left-content {
  width: 320px;
  font: normal 19px/32px "Alegreya";
}
.footer-title {
  font: normal bold 30px/1 "Alegreya";
  margin-bottom: 1.5rem;
}
.footer-second p {
  font: normal 19px/1 "Alegreya";
}
.footer-third li {
  font: normal 19px/1 "Alegreya";
  margin-bottom: 15px;
  list-style: none;
}
.footer-link {
  text-decoration: none;
  color: white;
}
.footer-link:hover {
  color: yellow;
}
.footer-third ul {
  padding-left: 0 !important;
}
.footer-img-flex {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}
.footer-img-flex img {
  width: 80px;
  height: 70px;
  border-radius: 10px;
}
.footer-icon {
  font-size: 25px;
  margin-right: 10px;
}
.footer-icon-two {
  margin-right: 7px;
  font-size: 20px;
}
.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.footer-btn {
  outline: none;
  border: none;
  background-color: white;
  padding: 5px;
  font: normal bold 18px/1 "Alegreya";
  color: #1a237e;
}

/* Footer-bottom-Flex */

.footer-bottom-flex {
  background-color: #0e1843;
  padding: 3rem 0;
  color: white;
  border-top: 0.1px solid #e7e7ee;
}
.footer-btm-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-copyright {
  font: normal 20px/1 "Alegreya";
  margin: 0;
}
.map-footer iframe {
  border-radius: 10px;
}
.footer-copyright span a {
  text-decoration: none;
  color: #dce775;
  font: normal bold 20px/1 "Alegreya";
  padding-left: 5px;
  transition: 0.5s all ease;
}
.footer-copyright span a:hover {
  color: yellow;
  text-decoration: underline;
}
/* Media Querry */
@media only screen and (max-width: 760px) {
  .footer-flex {
    gap: 2rem;
    flex-direction: column;
    padding: 0 20px;
  }
  .footer-title {
    font: normal bold 25px/1 "Alegreya";
    margin-bottom: 15px;
  }
  .footer-left-content {
    font: normal 18px/27px "Alegreya";
    width: 100%;
  }
  .footer-copyright {
    font: normal 17px/22px "Alegreya";
  }
}
