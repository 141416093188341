@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700&family=Roboto:ital,wght@0,300;0,400;1,300&display=swap");
.process-sub-main {
  padding: 3rem 0;
  background-color: rgba(247, 242, 242, 0.5);
}
.process-sub-body-title {
  font: normal bold 33px/1 "alegreya";
  margin-bottom: 65px;
  text-align: center;
}
.process-sub-body {
  margin: 0 6rem;
}
.process-sub-img-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5rem 0;
  gap: 3rem;
}

.process-sub-img-flex img {
  width: 350px;
  height: 330px;
  border-radius: 10px;
  object-fit: cover;
  transition: all 1s ease-in-out;
}
.process-sub-img-flex img:hover {
  transform: scale(1.08);
}
.process-sub-para {
  font: normal 18px/35px "alegreya";
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #0e1843;
  color: white;
  transition: 0.5s all ease-in;
}

.process-sub-para:hover {
  transform: scale(1.04);
}
.process-sub-img-flex img.animate {
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .process-sub-body {
    margin: 10px;
  }
  .process-sub-main {
    padding: 10px;
  }
  .process-sub-body-title {
    font: normal bold 30px/1 "Alegreya";
    margin: 10px 0;
  }
  .process-sub-img-flex {
    margin: 30px 0;
  }
  .process-sub-img-flex img{
    width: 100%;
  }
}
